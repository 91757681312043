import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const CheckList = makeShortcode("CheckList");
const CheckListItem = makeShortcode("CheckListItem");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av meldinger`}</h2>
    <p>{`I designsystemet finnes det 3 forskjellige meldingskomponenter, og hvilken som skal brukes kommer an på hva slags type informasjon du ønsker å gi brukeren. Felles for alle er at de bør holdes korte og konsise, og inneholde relevante tips eller informasjon som brukeren trenger å vite. Eksempler på situasjoner der meldinger kan brukes er når systemet er nede, betaling er gjennomført eller at det kommer en eller flere endringer på en tjeneste.`}</p>
    <p>{`Målet med meldinger er å bekrefte og skape trygghet hos brukerne, slik at de opplever at de har kontroll over situasjonen.`}</p>
    <p>{`Noen prinsipper vi bør følge:`}</p>
    <ul>
      <li parentName="ul">{`Meldingene skal være relevante og informative`}</li>
      <li parentName="ul">{`Selve meldingsboksen skal være responsiv, tilpasses lengden på innholdet og skaleres etter flaten den vises på (f.eks. full bredde på mobil)`}</li>
      <li parentName="ul">{`Teksten skal følge retningslinjene nevnt i `}<a parentName="li" {...{
          "href": "/profil/stil-og-tone/"
        }}>{`Stil og tone`}</a></li>
      <li parentName="ul">{`Hvis meldingen skal benyttes for å belyse feil og mangler i skjema, må meldingen inneholde lenker (`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{`) som peker mot skjemafeltet som må fylles ut på nytt`}</li>
      <li parentName="ul">{`Hvis meldingen dukker opp dynamisk ved endringer på siden, må den annonseres med `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`role="alert"`}</code></li>
    </ul>
    <h2>{`Valg av farge`}</h2>
    <p>{`Alle meldingskomponentene kommer i 4 farge-varianter.`}</p>
    <p>{`Hvilken av disse du skal bruke, avhenger av hva slags informasjon du prøver å formidle. Er det ren informasjon, som brukeren bør få med seg, er  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`info`}</code>{`-varianten god å bruke.`}</p>
    <h4>{`Tips / News`}</h4>
    <p>{`Tips kan brukes når du vil tipse brukeren om alternativer, eller andre valg. Dette kan også være informasjon som brukere ikke må forholde seg til. F.eks. tips til andre tjenester, forenkling av prosesser etc.`}</p>
    <h4>{`Info`}</h4>
    <p>{`Info brukes der du vil informere om noe som ikke er kritisk. Det kan for eksempel være planlagt vedlikehold, endring av tjenester eller ønske om at brukerne bekrefter kontaktinformasjon.`}</p>
    <h4>{`Suksess`}</h4>
    <p>{`Suksess-varianten brukes til å formidle «gode nyheter», eller når ønsket respons på en handling skjer. Det kan f.eks. være om en tjeneste blir tilgjengelig igjen, eller om betalingen ble gjennomført.`}</p>
    <h4>{`Error`}</h4>
    <p>{`Error-varianten brukes bare i sammenhenger der det har skjedd en feil, eller hvis det er en pågående feil. Det kan f.eks. være at en tjeneste er nede akkurat nå, eller at en betaling ikke gikk igjennom.`}</p>
    <p>{`Vær oppmerksom på at alle feilmeldinger automatisk får `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`role="alert"`}</code>{`. Det gjør at en skjermleser automatisk vil lese opp innholdet i meldingen med en gang den vises. Hvis meldingen er tilstede ved initiell sidelasting leses den opp like etter sidetittel. Dette kan slås av, se eksempelet under:`}</p>
    <DSExample name="messages_message_box_ErrorMessage_alertFalse" mdxType="DSExample" />
    <h2>{`MessageBox`}</h2>
    <p>{`Meldingsboksene skal inneholde informasjon som er nyttig og relevant for brukerne. De skal holdes konsise og ikke brukes til å forklare ting «man ikke finner noe annet sted til».`}</p>
    <p>{`Bruk meldingsbokser:`}</p>
    <CheckList mdxType="CheckList">
    <CheckListItem mdxType="CheckListItem">Når du trenger å informere kort om et tema</CheckListItem>
    <CheckListItem mdxType="CheckListItem">Der du vil utheve noe informasjon fra en større sammenheng</CheckListItem>
    <CheckListItem mdxType="CheckListItem">Når du har innhold som skal utheves, men som er selvforklarende uten kontekst</CheckListItem>
    </CheckList>
    <DSExample name="messages_message_box_TipsMessage" mdxType="DSExample" />
    <DSExample name="messages_message_box_InfoMessage" mdxType="DSExample" />
    <DSExample name="messages_message_box_SuccessMessage" mdxType="DSExample" />
    <DSExample name="messages_message_box_ErrorMessage" mdxType="DSExample" />
    <UUTestReport reportID="Messages_MessageBox" mdxType="UUTestReport" />
    <h3>{`Med liste`}</h3>
    <DSExample name="messages_message_box_InfoMessageList" mdxType="DSExample" />
    <h2>{`SystemMessage`}</h2>
    <p>{`Systemmeldinger skal bare brukes til viktige, midlertidige meldinger. De plasseres direkte under headeren og det skal helst bare brukes 1 per side.`}</p>
    <p>{`Bruk systemmelding:`}</p>
    <CheckList mdxType="CheckList">
    <CheckListItem mdxType="CheckListItem">Der meldingen skal vises i en gitt tidsperiode</CheckListItem>
    <CheckListItem mdxType="CheckListItem">Når det skal brukes til informasjon, som ikke krever en handling fra bruker</CheckListItem>
    </CheckList>
    <p>{`Ikke bruk systemmelding:`}</p>
    <CheckList mdxType="CheckList">
    <CheckListItem isCross={true} mdxType="CheckListItem">Der det ikke gir mening at meldingen er rett under header</CheckListItem>
    <CheckListItem isCross={true} mdxType="CheckListItem">Der det skal vises mange meldinger samtidig</CheckListItem>
    </CheckList>
    <DSExample name="messages_system_message_SystemInfoMessage" mdxType="DSExample" />
    <DSExample name="messages_system_message_SystemNewsMessage" mdxType="DSExample" />
    <DSExample name="messages_system_message_SystemSuccessMessage" mdxType="DSExample" />
    <DSExample name="messages_system_message_SystemErrorMessage" mdxType="DSExample" />
    <UUTestReport reportID="Messages_SystemMessage" mdxType="UUTestReport" />
    <h2>{`ContextMessage`}</h2>
    <p>{`Kontekstuelle meldinger er informasjon som skal gis i en kontekst. Dette er meldinger som skal utheves, men som bare gir mening i konteksten den er plassert. I motsetning til systemmeldinger, kan disse meldingene plasseres midt i innholdet på siden.`}</p>
    <p>{`Bruk kontekstuelle meldinger:`}</p>
    <CheckList mdxType="CheckList">
    <CheckListItem mdxType="CheckListItem">Når du vil gi ekstra informasjon i en gitt situasjon</CheckListItem>
    </CheckList>
    <p>{`Ikke bruk kontekstuelle meldinger:`}</p>
    <CheckList mdxType="CheckList">
    <CheckListItem isCross={true} mdxType="CheckListItem">Hvis du egentlig bare trenger en brødtekst</CheckListItem>
    </CheckList>
    <p>{`Alle variantene som kompakt finnes i alle fargevariantene.`}</p>
    <DSExample name="messages_context_message_ContextInfoMessage" mdxType="DSExample" />
Kompakt versjon med mindre padding:
    <DSExample name="messages_context_message_ContextInfoMessage_compact" mdxType="DSExample" />
Med tittel:
    <DSExample name="messages_context_message_ContextSuccessMessage_header" mdxType="DSExample" />
Kan lukkes
    <DSExample name="messages_context_message_ContextErrorMessage_showCloseButton" mdxType="DSExample" />
    <UUTestReport reportID="Messages_ContextMessage" mdxType="UUTestReport" />
    <h2>{`Bruk på andre bakgrunner`}</h2>
    <p>{`I tilfeller der meldingsboksene ikke får god nok kontrast mot valgt bakgrunnsfarge, finnes det en alternativ versjon. Den alternative versjonen gir meldingsboksen hvit bakgrunn i light-mode, men beholder standard farge i darkmode. Du skrur på denne versjonen ved å bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onColoredBg`}</code>{`-propertyen i hvilken som helst av meldingskomponentene.`}</p>
    <DSExample name="messages_message_box_InfoMessage_coloredbg" noPadding mdxType="DSExample" />
    <DSExample name="messages_context_message_ContextSuccessMessage_coloredbg" noPadding mdxType="DSExample" />
    <DSExample name="messages_context_message_ContextErrorMessage_coloredbg" noPadding mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      